import React from "react";

const WhatsappIcon = () => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 1.44 1.44" xmlns="http://www.w3.org/2000/svg"><path fill="#000229" d="M1.167 0.243A0.627 0.627 0 0 0 0.096 0.684 0.6 0.6 0 0 0 0.18 0.996L0.09 1.32l0.333 -0.087a0.6 0.6 0 0 0 0.3 0.075A0.624 0.624 0 0 0 1.167 0.243m-0.444 0.96a0.5 0.5 0 0 1 -0.285 -0.084L0.24 1.173l0.054 -0.192a0.54 0.54 0 0 1 -0.093 -0.297 0.522 0.522 0 1 1 0.522 0.519"/><path fill="#000229" d="M1.008 0.816a0.99 0.99 0 0 0 -0.108 -0.051c-0.012 -0.006 -0.024 -0.009 -0.033 0.006s-0.042 0.051 -0.051 0.063a0.024 0.024 0 0 1 -0.033 0.003 0.501 0.501 0 0 1 -0.126 -0.078 0.501 0.501 0 0 1 -0.087 -0.108 0.02 0.02 0 0 1 0.006 -0.03l0.024 -0.03 0.015 -0.024a0.03 0.03 0 0 0 0 -0.027c-0.006 -0.009 -0.036 -0.084 -0.048 -0.117s-0.027 -0.027 -0.036 -0.027h-0.03a0.05 0.05 0 0 0 -0.042 0.021 0.17 0.17 0 0 0 -0.054 0.129 0.3 0.3 0 0 0 0.063 0.162c0.009 0.009 0.111 0.168 0.267 0.234a0.501 0.501 0 0 0 0.09 0.033 0.2 0.2 0 0 0 0.099 0.006c0.03 -0.003 0.093 -0.036 0.105 -0.072s0.015 -0.069 0.009 -0.075a0.099 0.099 0 0 0 -0.03 -0.018"/></svg>
    </>
  );
};

export default WhatsappIcon;
